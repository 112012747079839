<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Project Code - Nickname</label>

            <b-form-select
              v-model="filter.code"
              :options="formattedProjectOptions"
              size="lg"
              @change="setNickname($event)"
            ></b-form-select>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-input
              v-model="filter.nickname"
              disabled
              size="lg"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-5">
          <div class="d-flex">
            <button class="btn btn-primary btn-md mr-2" @click="getReports()">
              <i class="flaticon2-search-1"></i> Show
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <b-tabs content-class="mt-3">
          <b-tab title="Report Man Hour" active>
            <div class="row mb-5">
              <div class="col-md-4 py-2 col-sm-12">
                <button
                  @click="
                    exportExcelA
                  "
                  class="btn btn-success btn-md mr-2"
                  :disabled="btExcelAdisabled"
                >
                  <i class="fa fa-file-excel" /> Excel
                </button>

                <button @click="exportPdf" class="btn btn-danger btn-md mr-2">
                  <i class="fa fa-file-pdf" /> PDF
                </button>
              </div>

              <div class="col-md-4 offset-md-4 py-2 col-sm-12"></div>
            </div>

            <complete-table
              id="tableContentManHour"
              :loading="loading"
              :data="manHours"
              custom-table-id="manHourTable"
            >
              <template #header>
                <tr class="text-left">
                  <th style="min-width: 200px" class="pl-7" rowspan="3">
                    <span class="text-dark-75">Design Number</span>
                  </th>
                  <th style="min-width: 250px" rowspan="3">Numbering</th>
                  <th style="min-width: 250px" rowspan="3">Title</th>
                  <th style="min-width: 250px" rowspan="3">Station</th>
                  <th style="min-width: 150px" rowspan="3">Scope</th>
                  <th style="min-width: 150px" rowspan="3">Category</th>
                  <th style="min-width: 150px" rowspan="3">Plan Date</th>
                  <th style="min-width: 150px" rowspan="3">Type</th>
                  <th style="min-width: 150px" colspan="6" class="text-center">
                    Man Hour Allocation
                  </th>
                  <th style="min-width: 150px" rowspan="3">Total Man Hour</th>
                </tr>

                <tr class="text-left">
                  <th style="min-width: 150px" colspan="2" class="text-center">
                    Design
                  </th>
                  <th style="min-width: 150px" colspan="2" class="text-center">
                    Drafter
                  </th>
                  <th style="min-width: 150px" colspan="2" class="text-center">
                    Checker
                  </th>
                </tr>

                <tr class="text-left">
                  <th style="min-width: 150px" class="text-center">PIC</th>
                  <th style="min-width: 150px" class="text-center">Man Hour</th>
                  <th style="min-width: 150px" class="text-center">PIC</th>
                  <th style="min-width: 150px" class="text-center">Man Hour</th>
                  <th style="min-width: 150px" class="text-center">PIC</th>
                  <th style="min-width: 150px" class="text-center">Man Hour</th>
                </tr>
              </template>

              <template #defaultBody="{item, i}">
                <tr
                  v-bind:key="item.numbering + 'default' + i"
                  class="border-0"
                >
                  <td class="pl-0 pt-8">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.no }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.numbering }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.title }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.station ? item.station.name : "-" }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.scope ? item.scope.name : "-" }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.category ? item.category.name : "-" }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{
                        item.plan_date
                          ? moment(item.plan_date).format("DD-MM-YYYY")
                          : "-"
                      }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.type ? item.type.name : "-" }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8 text-center">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.designer_pic ? item.designer_pic.name : "-" }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8 text-center">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.designer_hour }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8 text-center">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.drafter_pic ? item.drafter_pic.name : "-" }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8 text-center">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.drafter_hour }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8 text-center">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.checker_pic ? item.checker_pic.name : "-" }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8 text-center">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.checker_hour }}
                    </span>
                  </td>

                  <td class="pl-0 pt-8 text-center">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ item.total_man_hour }}
                    </span>
                  </td>
                </tr>
              </template>
            </complete-table>

            <div hidden>
              <VueHtml2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-orientation="landscape"
                pdf-content-width="2500px"
                ref="pdfReportingManHour"
                :html-to-pdf-options="{
                  filename: `${filter.code}-${filter.nickname} - reporting man hour`,
                  margin: 50,
                  jsPDF: {
                    unit: 'mm',
                    format: 'a1',
                    orientation: 'landscape',
                  },
                }"
              >
                <section slot="pdf-content">
                  <div class="d-flex justify-content-between">
                    <img :src="logo" style="height: 200px !important;" />

                    <h1>
                      Reporting Man Hour
                    </h1>

                    <div>
                      <h3>Day/Date : {{ dateNow }}</h3>
                      <h3>Time : {{ timeNow }}</h3>
                      <h3>Project Name : {{ filter.nickname }}</h3>
                      <h3>Project Code : {{ filter.code }}</h3>
                    </div>
                  </div>

                  <table id="exportManHourTable">
                    <thead>
                      <tr class="text-left">
                        <th style="min-width: 200px" class="pl-7" rowspan="3">
                          <span class="text-dark-75">Design Number</span>
                        </th>
                        <th style="min-width: 250px" rowspan="3">Numbering</th>
                        <th style="min-width: 250px" rowspan="3">Title</th>
                        <th style="min-width: 250px" rowspan="3">Station</th>
                        <th style="min-width: 150px" rowspan="3">Scope</th>
                        <th style="min-width: 150px" rowspan="3">Category</th>
                        <th style="min-width: 150px" rowspan="3">Plan Date</th>
                        <th style="min-width: 150px" rowspan="3">Type</th>
                        <th
                          style="min-width: 150px"
                          colspan="6"
                          class="text-center"
                        >
                          Man Hour Allocation
                        </th>
                        <th style="min-width: 150px" rowspan="3">
                          Total Man Hour
                        </th>
                      </tr>

                      <tr class="text-left">
                        <th
                          style="min-width: 150px"
                          colspan="2"
                          class="text-center"
                        >
                          Design
                        </th>
                        <th
                          style="min-width: 150px"
                          colspan="2"
                          class="text-center"
                        >
                          Drafter
                        </th>
                        <th
                          style="min-width: 150px"
                          colspan="2"
                          class="text-center"
                        >
                          Checker
                        </th>
                      </tr>

                      <tr class="text-left">
                        <th style="min-width: 150px" class="text-center">
                          PIC
                        </th>
                        <th style="min-width: 150px" class="text-center">
                          Man Hour
                        </th>
                        <th style="min-width: 150px" class="text-center">
                          PIC
                        </th>
                        <th style="min-width: 150px" class="text-center">
                          Man Hour
                        </th>
                        <th style="min-width: 150px" class="text-center">
                          PIC
                        </th>
                        <th style="min-width: 150px" class="text-center">
                          Man Hour
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <template v-for="(manHour, i) in manHours">
                        <tr
                          v-bind:key="'manhour' + manHour.numbering + i"
                          class="border-0"
                        >
                          <td class="pl-0 pt-8">
                            <p
                              class="ml-5 text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{ manHour.no }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{ manHour.numbering }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{ manHour.title }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{ manHour.station ? manHour.station.name : "-" }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{ manHour.scope ? manHour.scope.name : "-" }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{
                                manHour.category ? manHour.category.name : "-"
                              }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{ manHour.plan_date ? manHour.plan_date : "-" }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{ manHour.type ? manHour.type.name : "-" }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8 text-center">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{
                                manHour.designer_pic
                                  ? manHour.designer_pic.name
                                  : "-"
                              }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8 text-center">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{ manHour.designer_hour }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8 text-center">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{
                                manHour.drafter_pic
                                  ? manHour.drafter_pic.name
                                  : "-"
                              }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8 text-center">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{ manHour.drafter_hour }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8 text-center">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{
                                manHour.checker_pic
                                  ? manHour.checker_pic.name
                                  : "-"
                              }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8 text-center">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{ manHour.checker_hour }}
                            </p>
                          </td>

                          <td class="pl-0 pt-8 text-center">
                            <p
                              class=" text-dark-75 font-weight-bolder font-size-lg pdfText"
                            >
                              {{ manHour.total_man_hour }}
                            </p>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </section>
              </VueHtml2pdf>
            </div>
          </b-tab>

          <b-tab title="Lampiran B">
            <div class="row mb-5">
              <div class="col-md-4 py-2 col-sm-12">
                <button
                  @click="
                    exportExcelB
                  "
                  class="btn btn-success btn-md mr-2"
                  :disabled="btExcelBdisabled"
                >
                  <i class="fa fa-file-excel" /> Excel
                </button>

                <button
                  @click="exportPdfB"
                  class="btn btn-danger btn-md mr-2"
                  :disabled="btPdfBdisabled"
                >
                  <i class="fa fa-file-pdf" /> PDF
                </button>
              </div>

              <div class="col-md-4 offset-md-4 py-2 col-sm-12"></div>
            </div>

            <complete-table
              custom-table-id="tableManHourAttachmentB"
              :loading="loading"
              :data="manHourAttachments ? manHourAttachments.man_hours : []"
            >
              <template #header>
                <tr class="text-left">
                  <th class="text-center" style="min-width: 150px" rowspan="3">
                    Name
                  </th>
                  <th class="text-center" style="min-width: 150px" rowspan="3">
                    Initial
                  </th>
                  <th class="text-center" style="min-width: 150px" rowspan="3">
                    Scope
                  </th>
                  <th class="text-center" style="min-width: 150px" rowspan="3">
                    Assigment
                  </th>
                  <th class="text-center" style="min-width: 150px" rowspan="3">
                    Total Work Hour
                  </th>
                  <th
                    style="min-width: 150px"
                    class="text-center"
                    :colspan="
                      manHourAttachments
                        ? manHourAttachments.stations.length * 2
                        : 1
                    "
                  >
                    Design Implementation Allocation
                  </th>
                </tr>

                <tr class="text-left">
                  <template
                    v-for="(station, i) in manHourAttachments
                      ? manHourAttachments.stations
                      : []"
                  >
                    <th class="text-center" :key="'key12' + i" colspan="2">
                      {{ station.name }}
                    </th>
                  </template>
                </tr>

                <tr class="text-left">
                  <template
                    v-for="(station, i) in manHourAttachments
                      ? manHourAttachments.stations
                      : []"
                  >
                    <th class="text-center" :key="i + station.name + 'design'">
                      Design Number
                    </th>

                    <th class="text-center" :key="i + station.code + 'work'">
                      Work Hour
                    </th>
                  </template>
                </tr>
              </template>

              <template #defaultBody="{ item, i }">
                <template v-for="(dataMan, manIndex) in item.man">
                  <tr :key="i + '-' + manIndex + 'man_index'">
                    <td class="pl-0 pt-8 text-center">
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ dataMan.pic ? dataMan.pic.name : "-" }}
                      </span>
                    </td>

                    <td class="pl-0 pt-8 text-center">
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ dataMan.pic ? dataMan.pic.code : "-" }}
                      </span>
                    </td>

                    <td class="pl-0 pt-8 text-center">
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ item.scope ? item.scope.name : "-" }}
                      </span>
                    </td>

                    <td class="pl-0 pt-8 text-center">
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{
                          dataMan.hour.length > 0
                            ? dataMan.hour[0].assignment
                            : "-"
                        }}
                      </span>
                    </td>

                    <td class="pl-0 pt-8 text-center">
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{
                          dataMan.hour.length > 0
                            ? dataMan.hour[0].total_hour
                            : "-"
                        }}
                      </span>
                    </td>

                    <template
                      v-for="(hourStation, hourStationIndex) in dataMan.hour
                        .length > 0
                        ? dataMan.hour[0].hour_station.length > 0
                          ? dataMan.hour[0].hour_station
                          : []
                        : []"
                    >
                      <td
                        :key="
                          hourStationIndex +
                            '-' +
                            manIndex +
                            '-' +
                            'hour_station_design_no'
                        "
                        class="pl-0 pt-8 text-center"
                      >
                        <span
                          class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ hourStation.design_no }}
                        </span>
                      </td>

                      <td
                        :key="
                          hourStationIndex +
                            '-' +
                            manIndex +
                            '-' +
                            'hour_station_hour'
                        "
                        class="pl-0 pt-8 text-center"
                      >
                        <span
                          class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ hourStation.hour }}
                        </span>
                      </td>
                    </template>
                  </tr>

                  <template
                    v-for="(hourOnly, hourOnlyIndex) in dataMan.hour.length > 0
                      ? dataMan.hour
                      : []"
                  >
                    <tr
                      :key="
                        dataMan.pic.name +
                          dataMan.pic.code +
                          item.scope.name +
                          hourOnlyIndex +
                          '-' +
                          manIndex +
                          'man_index-next1'
                      "
                      v-if="hourOnlyIndex > 0"
                    >
                      <td class="pl-0 pt-8 text-center"></td>

                      <td class="pl-0 pt-8 text-center"></td>

                      <td class="pl-0 pt-8 text-center"></td>

                      <td class="pl-0 pt-8 text-center">
                        <span
                          class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ hourOnly.assignment }}
                        </span>
                      </td>

                      <td class="pl-0 pt-8 text-center">
                        <span
                          class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ hourOnly.total_hour }}
                        </span>
                      </td>

                      <template
                        v-for="(hourStation,
                        hourStationIndex) in hourOnly.hour_station"
                      >
                        <td
                          :key="
                            hourStationIndex +
                              '-' +
                              manIndex +
                              '-' +
                              'hour_station_design_no'
                          "
                          class="pl-0 pt-8 text-center"
                        >
                          <span
                            class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                          >
                            {{ hourStation.design_no }}
                          </span>
                        </td>

                        <td
                          :key="
                            hourStationIndex +
                              '-' +
                              manIndex +
                              '-' +
                              'hour_station_hour'
                          "
                          class="pl-0 pt-8 text-center"
                        >
                          <span
                            class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                          >
                            {{ hourStation.hour }}
                          </span>
                        </td>
                      </template>
                    </tr>
                  </template>
                </template>
              </template>
            </complete-table>

            <div hidden>
              <VueHtml2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-orientation="landscape"
                pdf-content-width="2500px"
                ref="pdfManHourPlanB"
                :html-to-pdf-options="{
                  filename: `${filter.code}-${filter.nickname} - reporting manhour lampiran b`,
                  margin: 10,
                  jsPDF: {
                    unit: 'mm',
                    format: 'a1',
                    orientation: 'landscape',
                  },
                }"
              >
                <section slot="pdf-content">
                  <div class="d-flex justify-content-between">
                    <img :src="logo" style="height: 100px !important;" />

                    <h1>
                      Reporting Man Hour
                    </h1>

                    <div>
                      <h3>Day/Date : {{ dateNow }}</h3>
                      <h3>Time : {{ timeNow }}</h3>
                      <h3>Project Name : {{ filter.nickname }}</h3>
                      <h3>Project Code : {{ filter.code }}</h3>
                    </div>
                  </div>

                  <table id="exportTableManHourAttachmentB">
                    <thead>
                      <tr class="text-left">
                        <th
                          class="text-center"
                          style="min-width: 150px"
                          rowspan="3"
                        >
                          Name
                        </th>
                        <th
                          class="text-center"
                          style="min-width: 150px"
                          rowspan="3"
                        >
                          Initial
                        </th>
                        <th
                          class="text-center"
                          style="min-width: 150px"
                          rowspan="3"
                        >
                          Scope
                        </th>
                        <th
                          class="text-center"
                          style="min-width: 150px"
                          rowspan="3"
                        >
                          Assigment
                        </th>
                        <th
                          class="text-center"
                          style="min-width: 150px"
                          rowspan="3"
                        >
                          Total Work Hour
                        </th>
                        <th
                          style="min-width: 150px"
                          class="text-center"
                          :colspan="
                            manHourAttachments
                              ? manHourAttachments.stations.length * 2
                              : 1
                          "
                        >
                          Design Implementation Allocation
                        </th>
                      </tr>

                      <tr class="text-left">
                        <template
                          v-for="(station, i) in manHourAttachments
                            ? manHourAttachments.stations
                            : []"
                        >
                          <th
                            class="text-center"
                            :key="i + 'station' + station.name"
                            colspan="2"
                          >
                            {{ station.name }}
                          </th>
                        </template>
                      </tr>

                      <tr class="text-left">
                        <template
                          v-for="(station, i) in manHourAttachments
                            ? manHourAttachments.stations
                            : []"
                        >
                          <th
                            style="min-width: 100px !important;"
                            class="text-center"
                            :key="i + station.name + 'station'"
                          >
                            Design Number
                          </th>

                          <th
                            style="min-width: 100px !important;"
                            class="text-center"
                            :key="i + station.code + 'code'"
                          >
                            Work Hour
                          </th>
                        </template>
                      </tr>
                    </thead>

                    <tbody>
                      <template
                        v-for="(item, i) in manHourAttachments
                          ? manHourAttachments.man_hours
                          : []"
                      >
                        <template v-for="(dataMan, manIndex) in item.man">
                          <tr :key="i + '-' + manIndex + 'man_index'">
                            <td class="pl-0 pt-8 text-center">
                              <span
                                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ dataMan.pic ? dataMan.pic.name : "-" }}
                              </span>
                            </td>

                            <td class="pl-0 pt-8 text-center">
                              <span
                                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ dataMan.pic ? dataMan.pic.code : "-" }}
                              </span>
                            </td>

                            <td class="pl-0 pt-8 text-center">
                              <span
                                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ item.scope ? item.scope.name : "-" }}
                              </span>
                            </td>

                            <td class="pl-0 pt-8 text-center">
                              <span
                                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{
                                  dataMan.hour.length > 0
                                    ? dataMan.hour[0].assignment
                                    : "-"
                                }}
                              </span>
                            </td>

                            <td class="pl-0 pt-8 text-center">
                              <span
                                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{
                                  dataMan.hour.length > 0
                                    ? dataMan.hour[0].total_hour
                                    : "-"
                                }}
                              </span>
                            </td>

                            <template
                              v-for="(hourStation, hourStationIndex) in dataMan
                                .hour.length > 0
                                ? dataMan.hour[0].hour_station.length > 0
                                  ? dataMan.hour[0].hour_station
                                  : []
                                : []"
                            >
                              <td
                                :key="
                                  hourStationIndex +
                                    '-' +
                                    manIndex +
                                    '-' +
                                    'hour_station_design_no'
                                "
                                class="pl-0 pt-8 text-center"
                              >
                                <span
                                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                                >
                                  {{ hourStation.design_no }}
                                </span>
                              </td>

                              <td
                                :key="
                                  hourStationIndex +
                                    '-' +
                                    manIndex +
                                    '-' +
                                    'hour_station_hour'
                                "
                                class="pl-0 pt-8 text-center"
                              >
                                <span
                                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                                >
                                  {{ hourStation.hour }}
                                </span>
                              </td>
                            </template>
                          </tr>

                          <template
                            v-for="(hourOnly, hourOnlyIndex) in dataMan.hour
                              .length > 0
                              ? dataMan.hour
                              : []"
                          >
                            <tr
                              :key="
                                dataMan.pic.name +
                                  dataMan.pic.code +
                                  item.scope.name +
                                  hourOnlyIndex +
                                  '-' +
                                  manIndex +
                                  'man_index-next2'
                              "
                              v-if="hourOnlyIndex > 0"
                            >
                              <td class="pl-0 pt-8 text-center"></td>

                              <td class="pl-0 pt-8 text-center"></td>

                              <td class="pl-0 pt-8 text-center"></td>

                              <td class="pl-0 pt-8 text-center">
                                <span
                                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                                >
                                  {{ hourOnly.assignment }}
                                </span>
                              </td>

                              <td class="pl-0 pt-8 text-center">
                                <span
                                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                                >
                                  {{ hourOnly.total_hour }}
                                </span>
                              </td>

                              <template
                                v-for="(hourStation,
                                hourStationIndex) in hourOnly.hour_station"
                              >
                                <td
                                  :key="
                                    hourStationIndex +
                                      '-' +
                                      manIndex +
                                      '-' +
                                      'hour_station_design_no'
                                  "
                                  class="pl-0 pt-8 text-center"
                                >
                                  <span
                                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                                  >
                                    {{ hourStation.design_no }}
                                  </span>
                                </td>

                                <td
                                  :key="
                                    hourStationIndex +
                                      '-' +
                                      manIndex +
                                      '-' +
                                      'hour_station_hour'
                                  "
                                  class="pl-0 pt-8 text-center"
                                >
                                  <span
                                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                                  >
                                    {{ hourStation.hour }}
                                  </span>
                                </td>
                              </template>
                            </tr>
                          </template>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </section>
              </VueHtml2pdf>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { mapState, mapGetters } from "vuex";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import { GET_PROJECT_DETAIL } from "../../../core/services/store/project.module";
import { GET_SCOPES } from "../../../core/services/store/scope.module";
import { showToast } from "../../../core/helper/toast.helper";
import {
  GET_REPORTING_MAN_HOUR,
  GET_REPORTING_MAN_HOUR_ATTACHMENT,
} from "../../../core/services/store/reporting-man-hour.module";
// import { toPdf } from "@/core/helper/pdf.helper";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";
import ApiService from "../../../core/services/api.service";

export default {
  components: { CompleteTable, VueHtml2pdf },
  data() {
    return {
      pdfDialog: false,
      pdfLoading: true,
      pdfList: [],
      filter: {
        code: null,
        nickname: null,
        scope: null,
        revision_version: null,
        jobdesk: [
          "interface",
          "design",
          "planner",
          "drafter",
          "koor",
          "enginer",
          "manager",
          "admin",
        ],
      },
      btPdfBdisabled: false,
      btExcelBdisabled: false,
      btExcelAdisabled: false,
      data: [
        {
          id: 123,
          site_document_type: {
            code: "asd",
            name: "asbuilt drawing",
            category: {
              code: "asd",
              name: "category name",
            },
          },
          project: {
            code: "LSR123123",
            name: "project name",
            nickname: "makpar 2",
          },
          complete: 0,
          storage: "/path/to/file.txt",
        },
        {
          id: 123,
          site_document_type: {
            code: "asd",
            name: "asbuilt drawing",
            category: {
              code: "asd",
              name: "category name",
            },
          },
          project: {
            code: "LSR123123",
            name: "project name",
            nickname: "makpar 2",
          },
          complete: 1,
          storage: "/path/to/file.txt",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projectDetails.projectDetails?.data,
      scopes: (state) => state.scope.scopes?.data,
      manHours: (state) => state.reportingManHour.manHours?.data,
      manHourAttachments: (state) =>
        state.reportingManHour.manHourAttachments?.data,
      loading: (state) => state.reportingManHour.loading,
    }),

    ...mapGetters(["layoutConfig"]),

    logo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    dateNow() {
      const today = new Date();

      return `${moment(today).format("dddd")}/ ${moment(today).format("ll")}`;
    },

    timeNow() {
      const today = new Date();

      return moment(today).format("LTS");
    },

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projects)) {
        projects = this.projects.map((project) => {
          return {
            value: project.code,
            text: `${project.code} - ${project.nickname}`,
          };
        });
      }

      return [
        { value: null, text: "Choose Project Code - Nickname" },
        ...projects,
      ];
    },

    formattedScopeOptions() {
      let scopes = [];

      if (arrayMoreThanOne(this.scopes)) {
        scopes = this.scopes.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Scope" }, ...scopes];
    },
  },
  methods: {
    exportExcel(tableID, filename) {
      let uri = "data:application/vnd.ms-excel;base64,",
        template =
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">' +
          '<head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>' +
          "</head>" +
          "<body><table>{table}</table></body></html>",
        base64 = function(s) {
          return window.btoa(unescape(encodeURIComponent(s)));
        },
        format = function(s, c) {
          return s.replace(/{(\w+)}/g, function(m, p) {
            return c[p];
          });
        };

      let table = null;

      table = document.getElementById(tableID);

      const ctx = {
        worksheet: "reporting-man-hour" || "Worksheet",
        table: table.innerHTML,
      };

      const link = document.createElement("a");

      link.setAttribute("href", uri + base64(format(template, ctx)));

      link.setAttribute("download", filename + ".xls");

      link.click();
    },

    exportPdf() {
      // window.URL.createObjectURL(
      //   process.env.VUE_APP_BASE_URL +
      //     `/report/manhour/A/${this.filter.code}/pdf`,
      //   "_blank",
      //   "noreferrer"
      // );

      var fileUrl = "";
      ApiService.setHeader();

      this.loading = true;

      ApiService.post("report/manhour/A/pdf", {
        code_project: this.filter.code,
      }).then((response) => {
        fileUrl = response.data.data;
        ApiService.postDownload("ftp/download", {
          file_url: fileUrl,
        })
          .then((responseDownload) => {
            const fileURL = window.URL.createObjectURL(
              new Blob([responseDownload?.data])
            );

            const fileLink = document.createElement("a");

            fileLink.href = fileURL;

            const pathArray = fileUrl.split("/");
            var fileName = pathArray.at(-1);

            fileLink.setAttribute("download", fileName);

            document.body.appendChild(fileLink);

            fileLink.click();

            this.loading = false;
          })
          .catch(() => {
            showToast("Failed", "Download failed, try again", "danger");
          });
      });

      // this.$refs.pdfReportingManHour.generatePdf();
    },

    exportPdfB() {
      var fileUrl = "";
      ApiService.setHeader();

      // this.loading = true;
      this.btPdfBdisabled = true;
      ApiService.post("report/manhour/B/pdf", {
        code_project: this.filter.code,
      }).then((response) => {
        fileUrl = response.data.data;
        ApiService.postDownload("ftp/download", {
          file_url: fileUrl,
        })
          .then((responseDownload) => {
            const fileURL = window.URL.createObjectURL(
              new Blob([responseDownload?.data])
            );

            const fileLink = document.createElement("a");

            fileLink.href = fileURL;

            const pathArray = fileUrl.split("/");
            var fileName = pathArray.at(-1);

            fileLink.setAttribute("download", fileName);

            document.body.appendChild(fileLink);

            fileLink.click();

            // this.loading = false;
            this.btPdfBdisabled = false;
          })
          .catch(() => {
            this.btPdfBdisabled = false;
            showToast("Failed", "Download failed, try again", "danger");
          });
      });
      // this.btPdfBdisabled = false;

      // this.$refs.pdfReportingManHour.generatePdf();
    },

    exportExcelB() {
      var fileUrl = "";
      ApiService.setHeader();

      // this.loading = true;
      this.btExcelBdisabled = true;
      ApiService.post("report/manhour/B/excel", {
        code_project: this.filter.code,
      }).then((response) => {
        fileUrl = response.data.data;
        ApiService.postDownload("ftp/download", {
          file_url: fileUrl,
        })
          .then((responseDownload) => {
            const fileURL = window.URL.createObjectURL(
              new Blob([responseDownload?.data])
            );

            const fileLink = document.createElement("a");

            fileLink.href = fileURL;

            const pathArray = fileUrl.split("/");
            var fileName = pathArray.at(-1);

            fileLink.setAttribute("download", fileName);

            document.body.appendChild(fileLink);

            fileLink.click();

            // this.loading = false;
            this.btExcelBdisabled = false;
          })
          .catch(() => {
            this.btExcelBdisabled = false;
            showToast("Failed", "Download failed, try again", "danger");
          });
      });
      // this.btPdfBdisabled = false;

      // this.$refs.pdfReportingManHour.generatePdf();
    },

    exportExcelA() {
      var fileUrl = "";
      ApiService.setHeader();

      // this.loading = true;
      this.btExcelAdisabled = true;
      ApiService.post("report/manhour/A/excel", {
        code_project: this.filter.code,
      }).then((response) => {
        fileUrl = response.data.data;
        ApiService.postDownload("ftp/download", {
          file_url: fileUrl,
        })
          .then((responseDownload) => {
            const fileURL = window.URL.createObjectURL(
              new Blob([responseDownload?.data])
            );

            const fileLink = document.createElement("a");

            fileLink.href = fileURL;

            const pathArray = fileUrl.split("/");
            var fileName = pathArray.at(-1);

            fileLink.setAttribute("download", fileName);

            document.body.appendChild(fileLink);

            fileLink.click();

            // this.loading = false;
            this.btExcelAdisabled = false;
          })
          .catch(() => {
            this.btExcelAdisabled = false;
            showToast("Failed", "Download failed, try again", "danger");
          });
      });
      // this.btPdfBdisabled = false;

      // this.$refs.pdfReportingManHour.generatePdf();
    },

    exportPdpAttachment() {
      this.$refs.pdfManHourPlanB.generatePdf();
    },

    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, {
        jobdesk: "design",
        params: this.filter.jobdesk,
      });
    },

    getScopes() {
      if (!arrayMoreThanOne(this.scopes)) {
        this.$store.dispatch(GET_SCOPES, {});
      }
    },

    getReports() {
      if (this.filter.code) {
        this.$store.dispatch(GET_REPORTING_MAN_HOUR, {
          params: {
            ...this.filter,
          },
          code: this.filter?.code,
        });

        this.$store.dispatch(GET_REPORTING_MAN_HOUR_ATTACHMENT, {
          params: {
            ...this.filter,
          },
          code: this.filter?.code,
        });
      } else {
        showToast("Warning", "Please Choose Project First", "danger");
      }
    },

    setNickname(value) {
      const project = this.projects?.find((project) => project?.code === value);

      this.filter.nickname = project?.nickname;

      this.filter.revision_version = project?.dmp_rev;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Report Man Hour" }]);

    this.getProjects();

    this.getScopes();
  },
};
</script>
<style>
.pdfText {
  word-wrap: break-word;
  white-space: initial;
}
</style>
